import React from 'react';
import { motion } from 'framer-motion';
import './HomePage.css';
import profileImage from '../assets/erik-web.JPG'; 
import ParticleBackground from './ParticleBackground';

const NavLink = ({ href, children }) => {
  return (
    <li>
      <a href={href}>{children}</a>
    </li>
  );
};

const HomePage = () => {
  return (
    <>
      <ParticleBackground />
      <motion.div 
        className="home-container"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ 
          opacity: 1, 
          scale: 1,
          y: [0, -10, 0]
        }}
        transition={{ 
          duration: 0.5,
          y: {
            repeat: Infinity, 
            duration: 4,
            ease: "easeInOut"
          }
        }}
      >
        <div className="content-wrapper">
          <div className="notch"></div>
          <div className="header-content">
            <h1 className="name">Dechen Han</h1>
            <h2 className="title">Full-Stack Developer</h2>
            <p className="subtitle">Class of 2025</p>
          </div>
          <div className="profile-image-container">
            <img src={profileImage} alt="Dechen Han" className="profile-image" />
          </div>
          <nav>
            <ul className="nav-links">
              <NavLink href="#about">About</NavLink>
              <NavLink href="#skills">Skills</NavLink>
              <NavLink href="#publications">Publications</NavLink>
              <NavLink href="#resume">Resume</NavLink>
              <NavLink href="#github">GitHub</NavLink>
            </ul>
          </nav>
        </div>
      </motion.div>
    </>
  );
};

export default HomePage;