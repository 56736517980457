import React, { useEffect, useRef } from 'react';

const ParticleBackground = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    // 设置画布大小
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    window.addEventListener('resize', resizeCanvas);
    resizeCanvas();

    // 粒子类
    class Snowflake {
      constructor() {
        this.x = Math.random() * canvas.width;
        this.y = Math.random() * canvas.height;
        this.size = Math.random() * 3 + 1.2; // 稍微增加最小尺寸
        this.speedX = Math.random() * 0.5 - 0.25; // 降低水平速度
        this.speedY = Math.random() * 0.5 + 0.5; // 垂直速度稍快，模拟下落
        this.opacity = Math.random() * 0.5 + 0.5; // 增加最小不透明度
      }

      update() {
        this.x += this.speedX;
        this.y += this.speedY;

        // 如果雪花飘出画布，重新从顶部开始
        if (this.y > canvas.height) {
          this.y = 0;
          this.x = Math.random() * canvas.width;
        }

        // 轻微的水平摆动
        this.x += Math.sin(this.y * 0.01) * 0.3;
      }

      draw() {
        ctx.save();
        ctx.shadowBlur = 5;
        ctx.shadowColor = 'rgba(255, 255, 255, 0.5)';
        ctx.fillStyle = `rgba(255, 255, 255, ${this.opacity})`;
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
        ctx.fill();
        ctx.restore();
      }
    }

    let snowflakes = [];
    const createSnowflakes = () => {
      for (let i = 0; i < 120; i++) { // 增加雪花数量
        snowflakes.push(new Snowflake());
      }
    };

    const animateSnowflakes = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      snowflakes.forEach(snowflake => {
        snowflake.update();
        snowflake.draw();
      });
      animationFrameId = requestAnimationFrame(animateSnowflakes);
    };

    createSnowflakes();
    animateSnowflakes();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, []);

  return <canvas ref={canvasRef} style={{ position: 'fixed', top: 0, left: 0, zIndex: -1 }} />;
};

export default ParticleBackground;
